<script setup lang="ts">
definePageMeta({
  middleware: [
    /**
     * If user is already logged in, redirect them to redirectTo route, instead of
     * sending them to signUp
     */
    (to) => {
      const { isAuthenticated } = useAuthAdapter()

      if (isAuthenticated.value === true) {
        const redirectTo = to.query.redirectTo as string ?? '/'
        return navigateTo(redirectTo)
      }
    },
  ],
})

const { signIn } = useAuthAdapter()
const route = useRoute('unauthorized')

onBeforeMount(() => {
  const redirectTo = route.query.redirectTo as string ?? '/'
  signIn({ callbackUrl: redirectTo })
})
</script>

<template>
  <LayoutBackLink hide-link>
    <template #title>
      Unauthorized
    </template>
    <div>
      <IconLoading />
      <span>Redirecting to login page...</span>
    </div>
  </LayoutBackLink>
</template>

<style scoped>

</style>
